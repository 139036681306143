<template>
  <l-content-widget
    sticky
    fullHeight
  >
    <template #widget>
      <l-widget
        vertical
        fullHeight
        no-ident
      >
        <template #header>
          <div class="d-xl-none w-100">
            <div class="row">
              <div class="col px-16 py-8">
                Константинский Константин Константинович
              </div>
              <v-divider vertical/>
              <div class="col-3 px-16 py-8">
                <b>Дата оценки:</b> 23.11.2021
              </div>

              <v-divider vertical/>

              <div class="col-3 px-16 py-8">
                <b>Итог:</b> {{ tabsInfo.total }}/{{ tabsInfo.totalPassing }} баллов
              </div>
            </div>
          </div>

          <div class="d-none d-xl-block p-16">
            <b>Константинский К.</b>
          </div>
        </template>

        <template #default>
          <div class="d-xl-none row align-items-center">
            <div class="col-9 px-16 py-8">
              <div
                class="text-truncate"
                title="27.08900.02 Машинист крана металлургического производства по обслуживания"
              >
                <b>Квалификация:</b> 27.08900.02 Машинист крана металлургического производства по обслуживания
              </div>
            </div>

            <v-divider vertical/>

            <div class="col-3 pl-0">
              <v-button
                :color="doneBtnState.color"
                :disabled="currentStepOfTest === 2"
                full-width
                @click="doneBtnHandler"
                class="border-radius-inherit"
                style="border-bottom-right-radius: 8px"
              >
                {{ doneBtnState.text }}
              </v-button>
            </div>
          </div>

          <div class="d-none d-xl-block border-radius-inherit">
            <div class="p-16">
              <p class="mb-0 text-size-12 text-color-default"><b>Дата оценки</b></p>
              <p>25.12.2021</p>
              <p class="mb-0 text-size-12 text-color-default"><b>Квалификация</b></p>
              <p class="mb-0">
                27.08900.02 Машинист крана металлургического производства по обслуживания
              </p>
            </div>

            <v-divider/>

            <v-list>
              <v-list-item
                v-for="(tab, tabIndex) in tabs"
                :key="tabIndex"
                :active="currentTabIndex === tabIndex"
                clickable
                hoverable
                @click="currentTabIndex = tabIndex"
              >
                <b>{{ tab.iconFull }}</b>
                <p class="m-0 text-color-default">Баллы: {{ tabsInfo[tab.id].total }}/{{ tab.passingScore }}</p>
              </v-list-item>

              <v-list-item
                :active="currentTabIndex === tabs.length"
                hoverable
                clickable
                @click="currentTabIndex = tabs.length"
              >
                <b>Итог: </b>
                {{ tabsInfo.total }}/{{ tabsInfo.totalPassing }} баллов
              </v-list-item>
            </v-list>

            <v-button
              :color="doneBtnState.color"
              :disabled="currentStepOfTest === 2"
              full-width
              @click="doneBtnHandler"
              class="d-none d-xl-flex border-radius-inherit"
            >
              {{ doneBtnState.text }}
            </v-button>
          </div>
        </template>
      </l-widget>
    </template>

    <template #default>
      <v-tabs
        v-model="currentTabIndex"
        size="lg"
      >
        <template
          v-for="(tab, tabIndex) in tabs"
          v-slot:[`slot-${tabIndex}`]="{ isActive }"
        >
          <div
            :key="tab.id"
            class="d-flex align-items-center justify-content-center"
          >
            <v-avatar
              :color="tabsInfo[tab.id].isDone ? 'success' : 'dark'"
            >
              {{ tab.icon }}
            </v-avatar>

            <span
              v-if="isActive"
              class="ml-12 text-size-16 text-nowrap"
              :class="{
                  'text-color-dark': !tabsInfo[tab.id].isDone,
                  'text-color-success': tabsInfo[tab.id].isDone,
                }"
            >
              {{ tab.iconFull }}
            </span>
          </div>
        </template>

        <template v-slot:[`slot-${tabs.length}`]="{ isActive }">
          <div class="d-flex align-items-center justify-content-center">
            <v-icon
              sprite-name="nlmk"
              fill="primary"
              name="total"
              size="24"
            />

            <b
              v-if="isActive"
              class="ml-12 text-nowrap text-size-16"
            >
              Итог
            </b>
          </div>
        </template>

        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
          :id="tab.id"
          class="p-16"
        >
          <h2 class="mb-24" v-html="tab.title"/>

          <p>
            <b>Задание:</b> <span v-html="tab.text"/>
          </p>

          <p>
            <b>Время выполнения:</b> <span v-html="tab.time"/>
          </p>

          <v-table>
            <v-table-tr class="bg-color-primary text-color-light">
              <v-table-th style="width: 60%">
                Критерии оценки
              </v-table-th>
              <v-table-th>
                Фактические баллы
              </v-table-th>
              <v-table-th style="width: 40%">
                Примечание
              </v-table-th>
            </v-table-tr>

            <template
              v-for="row of tab.rows"
            >
              <v-table-tr
                :key="row.id"
                :class="{
                     'bg-color-background-7': row.type === 'title' || row.type === 'divider'
                  }"
                :error="showErrors && row.input.value === '' && row.input.type === 'select'"
                class="align-top"
              >
                <v-table-td
                  :colspan="row.type === 'divider' ? 2 : 0"
                  v-html="row.text"
                  class="text-size-14"
                />

                <v-table-td v-if="row.input">
                  <div class="row justify-content-center">
                    <div class="col-auto">
                      <v-menu
                        v-if="row.input && row.input.type === 'select'"
                        :read-only="isReadonly"
                      >
                        <template #button>
                          <v-sheet
                            :disabled="isReadonly"
                            rounded
                            outlined
                            style="width: 150px"
                          >
                            <div class="row align-items-center flex-nowrap">
                              <div class="col pr-0">
                                <div class="p-8 text-center">
                                  {{ row.input.value !== '' ? `${row.input.value}/${row.input.maxValue}` : '?' }}
                                </div>
                              </div>
                              <v-divider
                                vertical
                              />
                              <div class="col-auto pl-0">
                                <v-button
                                  transparent
                                  icon
                                  size="xl"
                                >
                                  <v-icon
                                    stroke="primary"
                                    name="chevron-2"
                                    size="16"
                                    class="rotate-90"
                                  />
                                </v-button>
                              </div>
                            </div>
                          </v-sheet>
                        </template>

                        <div style="width: 257px">
                          <v-button
                            size="xl"
                            icon
                            transparent
                            v-for="option in createOptions(row.input.maxValue, row.input.customValues)"
                            :key="option.value"
                            @click="row.input.value = option.value"
                          >
                            {{ option.value }}
                          </v-button>
                        </div>
                      </v-menu>

                      <v-checkbox
                        v-else-if="row.input && row.input.type === 'checkbox'"
                        v-model="row.input.value"
                        :disabled="isReadonly"
                      />
                    </div>
                  </div>
                </v-table-td>

                <v-table-td v-if="row.comment">
                  <v-field
                    class="m-0"
                    :error="(
                          row.input.type === 'select' &&
                          !row.comment.value &&
                          row.input.value < row.input.maxValue &&
                          row.input.value !== ''
                          )"
                  >
                    <v-textarea
                      v-model="row.comment.value"
                      :rows="2"
                      :placeholder="row.comment.placeholder || 'Введите примечание'"
                      :disabled="isReadonly"
                      autogrow
                    />

                    <span class="v-field__error">
                      Пожалуйста, поясните свою оценку
                    </span>
                  </v-field>
                </v-table-td>
              </v-table-tr>
            </template>

            <v-table-tr class="bg-color-background-7">
              <v-table-td><b>Сумма баллов / Проходной балл</b></v-table-td>
              <v-table-td class="text-center"><b>{{ tabsInfo[tab.id].total }}/{{ tab.passingScore }}</b></v-table-td>
              <v-table-td></v-table-td>
            </v-table-tr>
          </v-table>
        </v-tab>

        <v-tab
          id="total"
          class="p-16"
        >
          <h2 class="mb-24">Итог</h2>

          <p>
            <b>
              Нарушения, зафиксированные в ходе практической части профессионального экзамена:
            </b>
          </p>

          <v-field class="mb-40">
            <v-textarea
              v-model="total.fouls"
              :rows="4"
              :disabled="isReadonly"
              placeholder="Введите текст"
              autogrow
            />
          </v-field>

          <p>
            <b>
              Результат:
            </b>
          </p>

          <v-table class="mb-16">
            <v-table-tr class="bg-color-primary text-color-light">
              <v-table-th>
                Задания
              </v-table-th>
              <v-table-th>
                Предварительный балл
              </v-table-th>
              <v-table-th>
                Фактический балл
              </v-table-th>
              <v-table-th>
                Проходной балл
              </v-table-th>
            </v-table-tr>

            <v-table-tr
              v-for="task in tabs"
              :key="task.id"
            >
              <v-table-th>
                {{ task.title }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo[task.id].total }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo[task.id].total }}
              </v-table-th>
              <v-table-th>
                {{ task.passingScore }}
              </v-table-th>
            </v-table-tr>

            <v-table-tr class="bg-color-background-7">
              <v-table-th>
                <b>По сумме всех заданий:</b>
              </v-table-th>
              <v-table-th>
                {{ tabsInfo.total }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo.total }}
              </v-table-th>
              <v-table-th>
                {{ tabsInfo.totalPassing }}
              </v-table-th>
            </v-table-tr>
          </v-table>

          <p class="mb-40">
            Квалификация соискателя

            <span v-html="conclusion"/>

            требованиям к квалификации.
          </p>

          <p>
            <b>
              Рекомендации эксперта в случае прохождения профессионального экзамена с неудовлетворительным результатом:
            </b>
          </p>

          <v-field
            :error="tabsInfo.isDone && tabsInfo.total < tabsInfo.totalPassing && !total.recommendations"
          >
            <v-textarea
              v-model="total.recommendations"
              :rows="4"
              :disabled="isReadonly"
              placeholder="Введите текст"
              autogrow
            />

            <span class="v-field__help ">
              Обязательно к заполнению в случае прохождения профессионального экзамена с неудовлетворительным результатом
            </span>
            <span class="v-field__error">
              Обязательно к заполнению в случае прохождения профессионального экзамена с неудовлетворительным результатом
            </span>
          </v-field>
        </v-tab>
      </v-tabs>

      <v-modal
        v-model="showModal"
      >
        <h4 slot="header">Внимание!</h4>

        Вы точно хотите завершить оценку? Далее вы не сможете изменить проставленные баллы и примечания.

        <div class="row mt-40">
          <div class="col-auto">
            <v-button
              @click="doneTest"
            >
              Завершить оценку
            </v-button>
          </div>
          <div class="col-auto">
            <v-button
              color="default"
              outlined
              @click="showModal = false"
            >
              Отмена
            </v-button>
          </div>
        </div>
      </v-modal>

      <v-snackbar
        v-model="showSnackbar"
        color="danger"
      >
        <b>Пожалуйста, поставьте все оценки</b>
      </v-snackbar>
    </template>
  </l-content-widget>
</template>

<script>
import { createCustomArray, getRandomUuid } from '@utils/helpers'
import { LContentWidget, LWidget } from '@/layouts'
import {
  VAvatar,
  VButton,
  VDivider,
  VTab,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr,
  VTabs
} from '@components/base'
import VField from '@components/base/VField/VField'
import VTextarea from '@components/base/VField/VTextarea/VTextarea'
import VCheckbox from '@components/base/VCheckbox/VCheckbox'
import VMenu from '@components/base/VMenu/VMenu'
import VSheet from '@components/base/VSheet/VSheet'
import VIcon from '@components/base/VIcon/VIcon'
import VModal from '@components/base/VModal/VModal'
import VSnackbar from '@components/base/VSnackbar/VSnackbar'
import VList from '@components/base/VList/VList'
import VListItem from '@components/base/VList/VListItem'

export default {
  name: 'SNlmkCOK',

  components: {
    VListItem,
    VList,
    VSnackbar,
    VModal,
    VIcon,
    VSheet,
    VMenu,
    VCheckbox,
    VTextarea,
    VField,
    VButton,
    VDivider,
    VAvatar,
    LContentWidget,
    LWidget,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr,
    VTab,
    VTabs
  },

  data () {
    return {
      currentTabIndex: 0,
      tabs: [
        {
          id: getRandomUuid(),
          title: 'Подготовить кран к выполнению крановых операций',
          text: 'выполнить мероприятия в рамках приемки смены. Провести проверку технического состояния крана и рельсового пути. Выполнить проверку работы механизмов крана в холостом режиме.',
          time: '40 минут',
          iconFull: 'Задание №1',
          icon: '1',
          passingScore: '38',
          rows: [
            {
              id: getRandomUuid(),
              text: `<p>
                    Внешний вид испытуемого соответствует требованиям охраны труда: в наличии каска,
                    защитный костюм, ботинки, очки, респиратор, шлемофон (беруши), перчатки (рукавицы);
                 </p>

                    Корректно указывает какие документы должен иметь при себе при выполнении работ на кране.
`,
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '2',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Перед приемкой смены знакомится с записями о состоянии крана в вахтенном журнале',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверена информация о проведении технического освидетельствования крана',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Получает ключ-марку (ключ-бирку) у ИТР, ответственного за безопасное производство работ с применением ПС с записью в журнале',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Проводит осмотр кабины крана, убеждается в:',
              type: 'title',
              input: {
                name: '2',
                type: 'select',
                value: '',
                maxValue: '6',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Минус 1 балл за каждое несоответствие'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Целостности остекления кабины (при наличии);',
              type: 'subquestion',
              input: {
                name: '2',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправности освещения;',
              type: 'subquestion',
              input: {
                name: '2',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Наличии диэлектрического коврика;',
              type: 'subquestion',
              input: {
                name: '2',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправности систем кондиционирования и обогрева;',
              type: 'subquestion',
              input: {
                name: '2',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправность состояния электрооборудования, в том числе целостность Видимых частей заземления электрооборудования кабины;',
              type: 'subquestion',
              input: {
                name: '2',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Наличии средств пожаротушения.',
              type: 'subquestion',
              input: {
                name: '2',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Производит визуальный осмотр мостового крана и убеждается в исправности:',
              type: 'title',
              input: {
                name: '2a',
                type: 'select',
                value: '',
                maxValue: '5',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Минус 1 балл за каждое несоответствие'
              }
            },

            {
              id: getRandomUuid(),
              text: 'сварных и болтовых соединений металлоконструкций;',
              type: 'subquestion',
              input: {
                name: '2a',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'троллеи и/ или гибкого токоподводящего кабеля (траковой цепи); люк кабины для осмотра троллей закрыт на замок;',
              type: 'subquestion',
              input: {
                name: '2a',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'целостность корпуса шкафов защитных панелей;',
              type: 'subquestion',
              input: {
                name: '2a',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'перильных ограждений моста и грузовой тележки;',
              type: 'subquestion',
              input: {
                name: '2a',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'рельсового пути грузовой тележки и моста крана',
              type: 'subquestion',
              input: {
                name: '2a',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Производит визуальный осмотр механизма передвижения крана, грузовых тележек:',
              type: 'title',
              input: {
                name: '3',
                type: 'select',
                value: '',
                maxValue: '7',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Минус 1 балл за каждое несоответствие'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Крепления редукторов и наличия в них масла;',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправность и крепление тормозов;',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправность крепления трансмиссионных валов и ходовых колес, в том числе предохранительных щитков ходовых колес;',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Крепления электродвигателей, целостности электропроводки, целостности видимого заземления;',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправность концевых выключателей и линеек;',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Целостность буферных устройств;',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Наличие ограждений (кожухов) вращающихся частей узлов и механизмов крана.',
              type: 'subquestion',
              input: {
                name: '3',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Производит осмотр механизмов подъема груза:',
              type: 'title',
              input: {
                name: '4',
                type: 'select',
                value: '',
                maxValue: '4',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Минус 1 балл за каждое несоответствие'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Исправность блоков полиспаста механизма подъема;',
              type: 'subquestion',
              input: {
                name: '4',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправность грузовых барабанов и крепления к ним грузовых канатов;',
              type: 'subquestion',
              input: {
                name: '4',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Исправность грузового каната;',
              type: 'subquestion',
              input: {
                name: '4',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Наличие, целостность, отсутствие механических повреждений ограничителя высоты подъема (опускания груза).',
              type: 'subquestion',
              input: {
                name: '4',
                type: 'checkbox',
                maxValue: '',
                value: false,
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },

            {
              id: getRandomUuid(),
              text: 'Производит пробный пуск крана «в холостую»:',
              type: 'divider',
              input: {
                name: '',
                type: '',
                maxValue: '',
                value: '',
                placeholder: ''
              },
              comment: null
            },

            {
              id: getRandomUuid(),
              text: 'Опускает траверсу или крюковую подвеску на уровень осмотра с пола, и выполняет осмотр на наличие неисправностей;',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '3',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет закрытие всех люков и калиток с проверкой блокировок (в зависимости от количества и типа крана',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет исправность звукового сигнала; ',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет работу нулевой блокировки;',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет работу механизма подъема;',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет исправность работы ограничителя высоты подъема;',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет работу механизма и ограничителя передвижения грузовой тележки;',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет работу механизма и ограничителя передвижения моста крана;',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проверяет действие тормозов',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            }
          ]
        },
        {
          id: getRandomUuid(),
          title: 'Выполнить перемещение груза согласно заданию',
          text: `
                <ol>
                    <li>
                    В соответствии с заданием эксперта ознакомиться с технологическими регламентами
                    (в том числе технологической картой погрузки и разгрузки, схемой складирования
                    груза, схемой строповки груза).
                    </li>
                    <li>Определить правильность подобранных съемных грузозахватных приспособлений.</li>
                    <li>
                    Выполнить подъем, перемещение, установку груза (короба, контейнера, пачки,
                    оборудования, рулона металла, бухты или другого аналогичного груза) из одной точки
                    цеха в другую с точной установкой.
                    </li>
                </ol>`,
          iconFull: 'Задание №2',
          time: '40 минут',
          icon: '2',
          passingScore: '53',
          rows: [
            {
              id: getRandomUuid(),
              text: 'С технологической картой погрузки и разгрузки, схемой строповки, технологической картой складирования груза испытуемый ознакомился самостоятельно до начала работ.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '2',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Грузоподъемная тара, грузозахватные приспособления, применены в соответствии с техническими регламентами, действующими на производственном участке.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Подан звуковой сигнал перед/во время подъема и перемещения груза.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '2',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Груз приподнят по сигналу стропальщика  на высоту 200-300 мм для проверки надежности тормозов и строповки груза.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: '<p>Не допускает перемещение груза над людьми:</p> В случае появления персонала по ходу движения груза, подает звуковой сигнал и прекращает движения до полного освобождения пути.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '15',
                customValues: [
                  { label: 0, value: 0 },
                  { label: 15, value: 15 }
                ],
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Критичный критерий, в случае несоблюдения практическое задание считается не выполненным.'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Перемещение и опускание груза произведено по сигналу стропальщика ',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Проведение погрузочно-разгрузочных работ выполнено в соответствии с  требованиями технологической карты погрузки-разгрузки и складирования груза.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '3',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Обеспечено безопасное освобождение грузозахватного органа от съемных грузозахватных приспособлений: дожидается сигнала стропальщика.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '10',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Грузозахватный орган поднят в верхнее положение по сигналу стропальщика.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Кран установлен на посадочную площадку.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Рукоятки контроллеров установлены в нулевое положение.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Ключ-марка (ключ-бирка) извлечена из защитной панели.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Главный рубильник защитной панели отключен.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '1',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            },
            {
              id: getRandomUuid(),
              text: 'Сделана запись в вахтенном журнале с  подписью об окончании смены в соответствии с производственной инструкцией.',
              type: 'question',
              input: {
                name: '1',
                type: 'select',
                maxValue: '5',
                value: '',
                placeholder: ''
              },
              comment: {
                value: '',
                placeholder: 'Введите примечание'
              }
            }
          ]
        }
      ],
      total: {
        fouls: '',
        recommendations: ''
      },
      showModal: false,
      showSnackbar: false,
      showErrors: false,
      doneBtnText: ['Начать оценку', 'Завершить оценку', 'Оценка завершена'],
      currentStepOfTest: 0
    }
  },

  watch: {
    currentTabIndex (newIndex) {
      localStorage.setItem('SNLMKCOK_tab-index', newIndex)
    },

    currentStepOfTest (nextStep) {
      localStorage.setItem('SNLMKCOK_test-step', nextStep)
    },

    total: {
      deep: true,
      handler () {
        localStorage.setItem('SNLMKCOK_tab-total', JSON.stringify(this.total))
      }
    }
  },

  created () {
    // !!! То что ниже должно быть в created, иначе будет перетираться из computed !!!
    // todo весь localstorage перенести в отдельный сервис
    if (localStorage.getItem('SNLMKCOK_tab-index')) {
      this.currentTabIndex = JSON.parse(localStorage.getItem('SNLMKCOK_tab-index'))
    }

    if (localStorage.getItem('SNLMKCOK_test-step')) {
      this.currentStepOfTest = JSON.parse(localStorage.getItem('SNLMKCOK_test-step'))
    }

    if (localStorage.getItem('SNLMKCOK_tab-total')) {
      this.total = JSON.parse(localStorage.getItem('SNLMKCOK_tab-total'))
    }

    if (localStorage.getItem('SNLMKCOK')) {
      this.tabs = JSON.parse(localStorage.getItem('SNLMKCOK'))
    } else {
      this.tabs.forEach(tab => {
        tab.rows
          .filter(row => row.input.type === 'select')
          .forEach(rowSelect => {
            rowSelect.comment.required = false
          })
      })
    }
  },

  computed: {
    isReadonly () {
      return this.currentStepOfTest !== 1
    },
    conclusion () {
      return this.tabsInfo.total >= this.tabsInfo.totalPassing
        ? '<b class="text-color-success">соответствует</b>'
        : '<b class="text-color-danger">не соответствует</b>'
    },

    isDoneBtnDisabled () {
      if (!this.tabsInfo.isDone) return true
      return this.tabsInfo.total < this.tabsInfo.totalPassing && !this.total.recommendations
    },

    doneBtnState () {
      return {
        text: this.doneBtnText[this.currentStepOfTest],
        color: (this.isDoneBtnDisabled && this.currentStepOfTest === 1) || this.currentStepOfTest === 2 ? 'default' : null
      }
    },

    tabsInfo () {
      localStorage.setItem('SNLMKCOK', JSON.stringify(this.tabs))

      return this.tabs.reduce((result, tab) => {
        const tabResult = tab.rows
          .filter(row => row.input.type === 'select')
          .reduce((rowResult, row) => {
            rowResult.total += +row.input.value

            if (!rowResult.isDone || row.input.value === '') {
              rowResult.isDone = false
              return rowResult
            }

            rowResult.isDone = !(row.input.value < row.input.maxValue && !row.comment.value)
            return rowResult
          }, {
            total: 0,
            totalPassing: +tab.passingScore,
            isDone: true
          })

        if (!tabResult.isDone || !result.isDone) {
          result.isDone = false
        }

        result.total += tabResult.total
        result.totalPassing += tabResult.totalPassing

        return {
          [tab.id]: tabResult,
          ...result
        }
      }, {
        total: 0,
        totalPassing: 0,
        isDone: true
      })
    }
  },

  methods: {
    doneTest () {
      this.currentStepOfTest = 2
      this.showModal = false
    },

    doneBtnHandler () {
      if (this.currentStepOfTest === 2) return

      if (this.currentStepOfTest === 0) {
        this.currentStepOfTest = 1
        return
      }

      if (this.isDoneBtnDisabled) {
        this.showSnackbar = true
        this.showErrors = true
      } else {
        this.showModal = true
      }
    },
    doneTestHandler () {
      delete localStorage.SNLMKCOK
      this.showModal = false
    },
    createOptions (maxValue, customValues) {
      if (customValues) return customValues

      return createCustomArray({
        length: +maxValue + 1,
        template: i => ({
          label: i,
          value: i
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

</style>
